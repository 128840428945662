












































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import vuex, { Global, User } from '@/store';
import get from 'lodash/get';
import API from '@/modules/API';
import { forgotPassword, changePassword } from '@/modules/ApiUsers';
import store from 'store';
import Utils from '@/modules/Utils';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class Login extends Vue {
  @User.State('error') error;
  @User.State('isAuth') isAuth;
  @Global.State('lang') lang;
  @User.State('project') project;

  username = '';
  password = '';
  passwordConfirm = '';
  mfaCode = '';
  isValid = false;
  disableEnter = false;
  pwOpened = false;
  pwIsLoading = false;
  pwIsComplete = false;
  isWitty = false;
  mfa = null;
  invalidPassword = '';
  email_recovery = '';

  beforeMount(){
    if (this.$route.path.includes('/authentication/reset_password') && !this.$route.query.cid){
      this.$router.push('/page-not-found');
    }
  }

  mounted() {
    this.isWitty = window.location.origin.includes('cms.witti.fr');
    document.getElementById('username').click();
  }

  @Watch('email_recovery')
  checkEmailAddressRecovery(){
    if (Utils.isValidEmailAddress(this.email_recovery) === null){
      vuex.commit('User/setError', 'Invalid email address');
    }else {
      vuex.commit('User/setError', '');
    }
  }

  login() {
    this.checkLoginInptus();
    if (this.disableEnter) return;
    
    this.disableEnter = true;
    this.sign({username: this.username, password: this.password}).then((res) => {
      if (res === true) {
        this.routeUserNextPage();
      } else if (res !== false) {
        store.set('user', res);
        this.mfa = res;
      }
    });
  }

  routeUserNextPage(){
    const user = store.get('user');

    if (user && user.company) {
      vuex.dispatch('User/fetchProjectsData');
      this.$router.push('/projects');
    }else if (user && user.allowed_companies && Object.keys(user.allowed_companies).length === 1) {
      const company_id = Object.keys(user.allowed_companies)[0];
      store.set('user', {...user, company: company_id});
      vuex.dispatch('User/fetchProjectsData');
      this.$router.push('/projects');
    }else if (user && user.allowed_companies){
      vuex.commit('Companies/setCompaniesList', user.allowed_companies);
      this.$router.push('/companies');
    }
  }

  @Watch('password')
  @Watch('username')
  checkLoginInptus(){
    vuex.commit('User/setError', '');
    this.disableEnter = true;

    if (!this.username) {
      (this.$refs.username as HTMLElement).focus();
      return;
    }
    if (!this.password) {
      return;
    }

    this.disableEnter = false;
  }

  async sign(data) {
    if (!data) return;
    const { username, password } = data;

    try {
      vuex.commit('User/setError', '');
      const res = await API.post('', 'login/login', { email: username, password }, { auth: false });
  
      if (res && res.hasOwnProperty('apikey')) {
        vuex.dispatch('User/init', { ...res, username });
        this.disableEnter = false;
        return true;
      } else if (!res) {
        vuex.commit('User/setError', 'Incorrect username or password');
        this.disableEnter = true;
        return false;
      } else { // login mfa
        this.disableEnter = false;
        return res;
      }
    } catch (e) {
      vuex.commit('Errors/setError', get(e, 'response.data.error.message', e));
      vuex.commit('Errors/setShowError', true);
      let error_data;
      try {
        error_data = await e['json']();
      }catch (e){}

      if (error_data && typeof error_data === 'string' && error_data.includes('expire')) {
        vuex.commit('User/setError', `Password has expired, please reset it by clicking on 'Frogot Password?'`);
      }else if (error_data && typeof error_data === 'string' && error_data.includes('inactive')) {
        vuex.commit('User/setError', `User is inactive, please contact support`);
      }else {
        vuex.commit('User/setError', 'Incorrect username or password');
      }
      this.disableEnter = true;
    }
  }

  openPasswordRecover() {
    this.pwOpened = true;
    this.pwIsLoading = false;
    this.pwIsComplete = false;
    this.email_recovery = this.username;
    vuex.commit('Errors/setShowError', false);
    vuex.commit('User/setError', '');
  }

  async recoveryPassword() {
    this.pwIsLoading = true;
    try {
      await forgotPassword(this.email_recovery);
      this.pwIsComplete = true;
    } catch (e) {
      vuex.commit('User/setError', 'Invalid email address');
    }
  }

  async loginMfa() {
    this.disableEnter = true;
    try{
      const res = await API.post(
        '',
        `companies/${this.mfa.company}/users/login_mfa`,
        { email: this.username, token: this.mfa.mfa ? this.mfa.mfa : '1', remote_token: this.mfaCode },
        { auth: false }
      );
      if (res && res.hasOwnProperty('apikey')) {
        vuex.dispatch('User/init', { ...res, username: this.username });
        vuex.commit('Errors/setShowError', false);
        vuex.commit('User/setError', '');
        this.routeUserNextPage();
      } else {
        vuex.commit('Errors/setShowError', true);
        vuex.commit('User/setError', 'Invalid mfa code');
        this.disableEnter = false;
      }
    } catch (e) {
      vuex.commit('Errors/setShowError', true);
      vuex.commit('User/setError', 'Invalid mfa code');
      this.disableEnter = false;
    }
  }
  backToLogin() {
    window.location.reload();
  }
  async addNewPass(){
    this.invalidPassword = '';
    const match1 = this.password.match(/[a-z]/i);
    if (match1 === null || typeof match1 !== 'object') {
      this.invalidPassword = this.$t('Password should contain at least 1 lowercase');
    }
    const match2 = this.password.match(/[0-9]/);
    if (match2 === null || typeof match2 !== 'object') {
      this.invalidPassword = this.$t(
        'Password should contain at least 1 number'
      );
    }
    const match3 = this.password.match(/[!@#$%^&*"]/);
    if (match3 === null || typeof match3 !== 'object') {
      this.invalidPassword = this.$t(
        'Password should contain at least 1 sign'
      );
    }
    const match4 = this.password.match(/[A-Z]/);
    if (match4 === null || typeof match4 !== 'object') {
      this.invalidPassword = this.$t('Password should contain at least 1 uppercase');
    }
    if (this.password !== this.passwordConfirm) {
      this.invalidPassword = this.$t('The password entered does not match the password confirmation');
    }
    if (this.invalidPassword === '') {
      await changePassword(this.username, this.$route.query.cid, this.$route.query.uuid, this.password).then((res) => {
        if (res.ok) {
          this.$router.push('/');
        } else{
          vuex.commit('Errors/setShowError', true);
          vuex.commit('User/setError', 'something went wrong');
        }
      });
    } else{
      vuex.commit('Errors/setShowError', true);
      vuex.commit('User/setError', this.invalidPassword);
    }
  }
}
